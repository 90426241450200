import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Reports} from '../../../helpers/Urls';

const INITIAL_STATE = {isFetching: false};

const SEND_ZAST_NOW_REPORT = 'SEND_ZAST_NOW_REPORT';
const SEND_ZAST_NOW_REPORT_COMPLETED = 'SEND_ZAST_NOW_REPORT_COMPLETED';
const SEND_ZAST_NOW_REPORT_FAILED = 'SEND_ZAST_NOW_REPORT_FAILED';

const SEND_PAYMENT_REPORT = 'SEND_PAYMENT_REPORT';
const SEND_PAYMENT_REPORT_COMPLETED = 'SEND_PAYMENT_REPORT_COMPLETED';
const SEND_PAYMENT_REPORT_FAILED = 'SEND_PAYMENT_REPORT_FAILED';

export const sendZastNowReport = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SEND_ZAST_NOW_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Reports.sendZastNowReportURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: SEND_ZAST_NOW_REPORT_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: SEND_ZAST_NOW_REPORT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SEND_ZAST_NOW_REPORT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const sendPaymentReport = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SEND_PAYMENT_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Reports.sendPaymentReportURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: SEND_PAYMENT_REPORT_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: SEND_PAYMENT_REPORT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SEND_PAYMENT_REPORT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case SEND_ZAST_NOW_REPORT:
    case SEND_PAYMENT_REPORT:
      return {...state, isFetching: true};
    case SEND_ZAST_NOW_REPORT_COMPLETED:
    case SEND_PAYMENT_REPORT_COMPLETED:
      return {...state, isFetching: false};
    case SEND_ZAST_NOW_REPORT_FAILED:
    case SEND_PAYMENT_REPORT_FAILED:
      return {...state, isFetching: false};

    default:
      return state;
  }
};
