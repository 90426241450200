import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Reports} from '../../../helpers/Urls';

const intialState = {
  isFetching: false,
  zoneReport: {},
};

export const FETCH_ZONE_WISE_DISTRIBUTION = 'FETCH_ZONE_WISE_DISTRIBUTION';
export const FETCH_ZONE_WISE_DISTRIBUTION_COMPLETED =
  'FETCH_ZONE_WISE_DISTRIBUTION_COMPLETED';
export const FETCH_ZONE_WISE_DISTRIBUTION_FAILED =
  'FETCH_ZONE_WISE_DISTRIBUTION_FAILED';

export const fetchZoneWiseDistribution = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_ZONE_WISE_DISTRIBUTION});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Reports.fetchZoneWiseDistributionURL(),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_ZONE_WISE_DISTRIBUTION_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_ZONE_WISE_DISTRIBUTION_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_ZONE_WISE_DISTRIBUTION_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = intialState, action) => {
  const {type, payload = {}} = action || {};
  switch (type) {
    case FETCH_ZONE_WISE_DISTRIBUTION:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_ZONE_WISE_DISTRIBUTION_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        error: '',
        zoneReport: Object.assign({}, payload),
      };
    case FETCH_ZONE_WISE_DISTRIBUTION_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload?.error,
        zoneReport: {},
      };
    default:
      return state;
  }
};
