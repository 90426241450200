const INITIAL_STATE = {
  visibleDrawer: false,
};

export const OPEN_DOCUMENT_DRAWER = 'OPEN_DOCUMENT_DRAWER';
export const CLOSE_DOCUMENT_DRAWER = 'CLOSE_DOCUMENT_DRAWER';

export const openDrawer = (modalType, entityId, data, active_tab) => {
  //entity id - booking id, trip id etc
  return (dispatch) => {
    dispatch({
      type: OPEN_DOCUMENT_DRAWER,
      payload: {modalType, entityId: entityId, data, active_tab},
    });
  };
};
export const closeDrawer = () => {
  return (dispatch) => {
    dispatch({type: CLOSE_DOCUMENT_DRAWER});
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case OPEN_DOCUMENT_DRAWER:
      return {
        visibleDrawer: true,
        modalType: payload.modalType,
        entityId: payload.entityId,
        //entity id - booking id, trip id etc
        data: payload.data,
        active_tab: payload.active_tab,
      };
    case CLOSE_DOCUMENT_DRAWER:
      return {
        visibleDrawer: false,
      };
    default:
      return state;
  }
};
