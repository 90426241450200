import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Reports} from '../../../helpers/Urls';

const intialState = {
  isFetching: false,
  vendorReport: {},
};

export const FETCH_VENDOR_DISTRIBUTION = 'FETCH_VENDOR_DISTRIBUTION';
export const FETCH_VENDOR_DISTRIBUTION_COMPLETED =
  'FETCH_VENDOR_DISTRIBUTION_COMPLETED';
export const FETCH_VENDOR_DISTRIBUTION_FAILED =
  'FETCH_VENDOR_DISTRIBUTION_FAILED';

export const fetchVendorWiseDistribution = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_VENDOR_DISTRIBUTION});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Reports.fetchVendorWiseDistributionURL(),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_VENDOR_DISTRIBUTION_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_VENDOR_DISTRIBUTION_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_VENDOR_DISTRIBUTION_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = intialState, action) => {
  const {type, payload = {}} = action || {};
  switch (type) {
    case FETCH_VENDOR_DISTRIBUTION:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_VENDOR_DISTRIBUTION_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        error: '',
        vendorReport: Object.assign({}, payload),
      };
    case FETCH_VENDOR_DISTRIBUTION_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload?.error,
        vendorReport: {},
      };
    default:
      return state;
  }
};
