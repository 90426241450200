import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Records} from '../../../helpers/Urls';
import UseAbortApiCall from '../../../Common/Hooks/use-abort';
import axios from 'axios';

const INITIAL_STATE = {
  isFetching: false,
  isDeletingVehicle: false,
  hasError: false,
  error: '',
  vehicleIds: [],
  vehicleCount: 0,
  search: '',
  resetPage: false,
  visibleDrawer: false,
};

const FETCH_VEHICLE_DETAILS = 'FETCH_VEHICLE_DETAILS';
const FETCH_VEHICLE_DETAILS_COMPLETED = 'FETCH_VEHICLE_DETAILS_COMPLETED';
const FETCH_VEHICLE_DETAILS_FAILED = 'FETCH_VEHICLE_DETAILS_FAILED';

const RESET_PAGE = 'RESET_PAGE';

const RESET_VEHICLE_FILTER = 'RESET_VEHICLE_FILTER';
export const OPEN_DOCUMENT_DRAWER = 'OPEN_DOCUMENT_DRAWER';
export const CLOSE_DOCUMENT_DRAWER = 'CLOSE_DOCUMENT_DRAWER';

const DELETE_VEHICLE = 'DELETE_VEHICLE';
const DELETE_VEHICLE_COMPLETED = 'DELETE_VEHICLE_COMPLETED';
const DELETE_VEHICLE_FAILED = 'DELETE_VEHICLE_FAILED';

export const resetFilters = () => {
  return (dispatch) => {
    dispatch({type: RESET_VEHICLE_FILTER});
  };
};
// export const openDrawer = (modalType, entityId, data, active_tab) => {
//   //entity id - booking id, trip id etc
//   return (dispatch) => {
//     dispatch({
//       type: OPEN_DOCUMENT_DRAWER,
//       payload: {modalType, entityId: entityId, data, active_tab},
//     });
//   };
// };
// export const closeDrawer = () => {
//   return (dispatch) => {
//     dispatch({type: CLOSE_DOCUMENT_DRAWER});
//   };
// };
export const fetchVehicleList = (page, search, resetPage = true) => {
  return async (dispatch) => {
    let response = {};
    let {cancelApiCall} = UseAbortApiCall('cancelPreviousRequestFetchDoList');
    try {
      dispatch({type: FETCH_VEHICLE_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        cancelToken: cancelApiCall(),
        url: Records.fetchVehiclesURL(page, search),
      });
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }

      if (status === true) {
        dispatch({type: FETCH_VEHICLE_DETAILS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_VEHICLE_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return {
          error: true,
        };
      }
      dispatch({
        type: FETCH_VEHICLE_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const deleteVehicleRecord = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DELETE_VEHICLE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Records.deleteVehicleRecordURL(),
        data: requestData,
      });
      const {data, status} = response || {};

      if (status === true) {
        // dispatch({type: DELETE_VEHICLE_COMPLETED, payload: data});
        // dispatch({
        //   type:FETCH_VEHICLE_DETAILS_COMPLETED,
        //   payload:data
        // })
        dispatch({
          type: DELETE_VEHICLE_COMPLETED,
          payload: data,
        });
      } else {
        const {message: error = 'Error'} = response || {};
        dispatch({type: DELETE_VEHICLE_FAILED, payload: {error}});
      }
    } catch (err) {
      dispatch({
        type: DELETE_VEHICLE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_VEHICLE_DETAILS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };

    case DELETE_VEHICLE:
      return {
        ...state,
        // isFetching: true
        isDeletingVehicle: true,
      };
    case DELETE_VEHICLE_COMPLETED:
      return {
        ...state,
        // isFetching: false,
        isDeletingVehicle: false,
        hasError: false,
      };
    case DELETE_VEHICLE_FAILED:
      return {
        ...state,
        //  isFetching: false
        isDeletingVehicle: false,
      };

    case FETCH_VEHICLE_DETAILS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        vehicleIds: payload?.vehicle_list,
        vehicleCount: payload?.total,
        total: payload?.total,
        search: payload.query_string,
        document_type: payload.document_type,
        can_upload_document: payload.can_upload_document,
      };
    case FETCH_VEHICLE_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
        vehicleIds: [],
      };
    case RESET_PAGE:
      return {...state, resetPage: !state.resetPage};

    case RESET_VEHICLE_FILTER:
      return {...state, search: ''};
    // case OPEN_DOCUMENT_DRAWER:
    //   return {
    //     visibleDrawer: true,
    //     modalType: payload.modalType,
    //     entityId: payload.entityId,
    //     //entity id - booking id, trip id etc
    //     data: payload.data,
    //     active_tab: payload.active_tab,
    //   };
    // case CLOSE_DOCUMENT_DRAWER:
    //   return {
    //     visibleDrawer: false,
    //   };
    default:
      return state;
  }
};
