import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Tracking} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  error: '',
  search: '',
  driverActionsIds: [],
  driverActions: {},
  driverLogsCount: 0,
  binCount: 0,
  driverUploadsCount: 0,
  resetPage: false,
  isRequesting: false,
};

const FETCH_DRIVER_TRACKING_DETAILS = 'FETCH_DRIVER_TRACKING_DETAILS';
const FETCH_DRIVER_TRACKING_DETAILS_COMPLETED =
  'FETCH_DRIVER_TRACKING_DETAILS_COMPLETED';
const FETCH_DRIVER_TRACKING_DETAILS_FAILED =
  'FETCH_DRIVER_TRACKING_DETAILS_FAILED';

const RESET_PAGE = 'RESET_PAGE';

const PAUSE_RESUME_TRACKING_LINK = 'PAUSE_RESUME_TRACKING_LINK';
const PAUSE_RESUME_TRACKING_LINK_COMPLETED =
  'PAUSE_RESUME_TRACKING_LINK_COMPLETED';
const PAUSE_RESUME_TRACKING_LINK_FAILED = 'PAUSE_RESUME_TRACKING_LINK_FAILED';

export const fetchPauseResumeTrackingLink = (requestData = {}) => {
  return async (dispatch) => {
    let res = {};
    try {
      dispatch({type: PAUSE_RESUME_TRACKING_LINK});
      res = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Tracking.pauseResumeTrackingLinkURL(),
        data: requestData,
      });
      const {data, status} = res || {};
      if (status) {
        dispatch({type: PAUSE_RESUME_TRACKING_LINK_COMPLETED});
      } else {
        dispatch({
          type: PAUSE_RESUME_TRACKING_LINK_FAILED,
          payload: {error: 'Error'},
        });
      }
    } catch {
      dispatch({
        type: PAUSE_RESUME_TRACKING_LINK_FAILED,
        payload: {error: 'Error'},
      });
    }
    return res;
  };
};

export const fetchDriverTrackingDetails = (search, resetPage = true) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_DRIVER_TRACKING_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Tracking.fetchDriverTrackingDetailsURL(search),
      });
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }
      if (status === true) {
        dispatch({
          type: FETCH_DRIVER_TRACKING_DETAILS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_DRIVER_TRACKING_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_DRIVER_TRACKING_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case PAUSE_RESUME_TRACKING_LINK:
      return {
        ...state,
        hasError: false,
        error: '',
      };
    case PAUSE_RESUME_TRACKING_LINK_COMPLETED: {
      return {
        ...state,
        hasError: false,
        error: '',
      };
    }
    case PAUSE_RESUME_TRACKING_LINK_FAILED: {
      return {
        hasError: true,
        error: payload.error,
      };
    }

    case FETCH_DRIVER_TRACKING_DETAILS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_DRIVER_TRACKING_DETAILS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        driverActionsIds: payload?.driver_actions_list,
        driverActions: payload?.driver_actions,
        driverLogsCount: payload?.count?.logs,
        binCount: payload?.count.bin,
        driverUploadsCount: payload?.count?.uploads,
        search: payload.query_string,
      };
    case FETCH_DRIVER_TRACKING_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
        driverActionsIds: [],
        driverActions: {},
      };
    case RESET_PAGE:
      return {
        ...state,
        resetPage: !state.resetPage,
      };
    default:
      return state;
  }
};
