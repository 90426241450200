import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Tracking} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isRequesting: false,
  hasError: false,
  error: '',
  vendorIds: [],
  search: '',
  vehicleData: '',
  resetPage: false,
};

const FETCH_MAP_VIEW_VENDOR_DETAILS = 'FETCH_MAP_VIEW_VENDOR_DETAILS';
const FETCH_MAP_VIEW_VENDOR_DETAILS_COMPLETED =
  'FETCH_MAP_VIEW_VENDOR_DETAILS_COMPLETED';
const FETCH_MAP_VIEW_VENDOR_DETAILS_FAILED =
  'FETCH_MAP_VIEW_VENDOR_DETAILS_FAILED';

const MAP_VIEW_VEHICLES = 'MAP_VIEW_VEHICLES';
const MAP_VIEW_VEHICLES_COMPLETED = 'MAP_VIEW_VEHICLES_COMPLETED';
const MAP_VIEW_VEHICLES_FAILED = 'MAP_VIEW_VEHICLES_FAILED';

const RESET_VENDOR_FILTER = 'RESET_VENDOR_FILTER';

export const resetFilters = () => {
  return (dispatch) => {
    dispatch({type: RESET_VENDOR_FILTER});
  };
};

export const fetchMapViewVendorRecords = (search) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_MAP_VIEW_VENDOR_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Tracking.fetchMapViewVendorRecordsURL(search),
      });
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: FETCH_MAP_VIEW_VENDOR_DETAILS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_MAP_VIEW_VENDOR_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_MAP_VIEW_VENDOR_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const mapViewVehicles = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: MAP_VIEW_VEHICLES});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Tracking.mapViewVehiclesURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: MAP_VIEW_VEHICLES_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: MAP_VIEW_VEHICLES_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: MAP_VIEW_VEHICLES_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case MAP_VIEW_VEHICLES:
    case FETCH_MAP_VIEW_VENDOR_DETAILS:
      return {
        ...state,
        isRequesting: true,
        hasError: false,
        error: '',
      };
    case MAP_VIEW_VEHICLES_COMPLETED:
      return {
        ...state,
        isRequesting: false,
        vehicleData: payload,
      };
    case FETCH_MAP_VIEW_VENDOR_DETAILS_COMPLETED:
      console.log('actions payload checking', payload?.vendors_list);
      return {
        ...state,
        isRequesting: false,
        vendorIds: payload?.vendors_list,
        search: payload.query_string,
      };
    case MAP_VIEW_VEHICLES_FAILED:
    case FETCH_MAP_VIEW_VENDOR_DETAILS_FAILED:
      return {
        ...state,
        isRequesting: false,
        hasError: true,
        vendorIds: [],
      };
    case RESET_VENDOR_FILTER:
      return {...state, search: ''};
    default:
      return state;
  }
};
