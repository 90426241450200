import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Finance} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  tripChargeId: null,
  tripAdvanceData: {},
  vendorId: null,
  tripId: null,
  updatingTripCharge: false,
  tripsWithoutCharges: [],
  tripsWithCharges: [],
  daysLimit: 20,
  sendingOTP: false,
  verifyingOTP: false,
  advancePayment: {},
};

const RESET_PAGE = 'RESET_PAGE';

export const UPDATE_REQUEST_STATUS = 'UPDATE_REQUEST_STATUS';
export const UPDATE_REQUEST_STATUS_COMPLETED =
  'UPDATE_REQUEST_STATUS_COMPLETED';
export const UPDATE_REQUEST_STATUS_FAILED = 'UPDATE_REQUEST_STATUS_FAILED';

export const FETCH_TRIP_ADVANCE = 'FETCH_TRIP_ADVANCE';
export const FETCH_TRIP_ADVANCE_COMPLETED = 'FETCH_TRIP_ADVANCE_COMPLETED';
export const FETCH_TRIP_ADVANCE_FAILED = 'FETCH_TRIP_ADVANCE_FAILED';

export const UPDATE_TRIP_CHARGE = 'UPDATE_TRIP_CHARGE';
export const UPDATE_TRIP_CHARGE_COMPLETED = 'UPDATE_TRIP_CHARGE_COMPLETED';
export const UPDATE_TRIP_CHARGE_FAILED = 'UPDATE_TRIP_CHARGE_FAILED';

export const FETCH_TRIP_CHARGES = 'FETCH_TRIP_CHARGES';
export const FETCH_TRIP_CHARGES_COMPLETED = 'FETCH_TRIP_CHARGES_COMPLETED';
export const FETCH_TRIP_CHARGES_FAILED = 'FETCH_TRIP_CHARGES_FAILED';

const ADD_NEW_ADVANCE = 'ADD_NEW_ADVANCE';
const ADD_NEW_ADVANCE_COMPLETED = 'ADD_NEW_ADVANCE_COMPLETED';
const ADD_NEW_ADVANCE_FAILED = 'ADD_NEW_ADVANCE_FAILED';

const SENDING_OTP = 'SENDING_OTP';
const SENDING_OTP_COMPLETED = 'SENDING_OTP_COMPLETED';
const SENDING_OTP_FAILED = 'SENDING_OTP_FAILED';

const VERIFY_OTP = 'VERIFY_OTP';
const VERIFY_OTP_COMPLETED = 'VERIFY_OTP_COMPLETED';
const VERIFY_OTP_FAILED = 'VERIFY_OTP_FAILED';

const ADD_BENEFICIARY = 'ADD_BENEFICIARY';
const ADD_BENEFICIARY_COMPLETED = 'ADD_BENEFICIARY_COMPLETED';
const ADD_BENEFICIARY_FAILED = 'ADD_BENEFICIARY_FAILED';

const CROSSDOCK_VERIFY_OTP_COMPLETED = 'CROSSDOCK_VERIFY_OTP_COMPLETED';
const HIDE_CROSSDOCK_TERMINATED_TRIP = 'HIDE_CROSSDOCK_TERMINATED_TRIP';
const HIDE_TERMINATED_TRIP = 'HIDE_TERMINATED_TRIP';

export const fetchTripAdvance = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TRIP_ADVANCE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Finance.fetchTripAdvanceURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: FETCH_TRIP_ADVANCE_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_TRIP_ADVANCE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TRIP_ADVANCE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const cashDiesel = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TRIP_ADVANCE});
      dispatch({type: FETCH_TRIP_ADVANCE_COMPLETED, payload: requestData});
    } catch (err) {
      dispatch({
        type: FETCH_TRIP_ADVANCE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const updateTripCharge = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: UPDATE_TRIP_CHARGE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Finance.updateTripChargeURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: UPDATE_TRIP_CHARGE_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: UPDATE_TRIP_CHARGE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_TRIP_CHARGE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchTripCharges = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TRIP_CHARGES});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Finance.fetchTripChargesURL(),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: FETCH_TRIP_CHARGES_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_TRIP_CHARGES_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TRIP_CHARGES_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

// const updateAddingAdvance = (state, payload) => {
//   const newAdvancePayment = {...payload};
//   newAdvancePayment.advance_payment = payload?.advance_payment;
//   return {...state, addingAdvance: false, advancePayment: newAdvancePayment};
// };

export const closeRequest = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Finance.closeRequestURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: ADD_NEW_ADVANCE});
        dispatch({type: ADD_NEW_ADVANCE_COMPLETED});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: ADD_NEW_ADVANCE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ADD_NEW_ADVANCE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const addAdvance = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ADD_NEW_ADVANCE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Finance.addAdvanceURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        // dispatch({type: ADD_NEW_ADVANCE});
        dispatch({type: ADD_NEW_ADVANCE_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: ADD_NEW_ADVANCE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ADD_NEW_ADVANCE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

//approve/reject/delete single api
export const updateRequestStatus = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: UPDATE_REQUEST_STATUS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Finance.updateRequestStatusURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: UPDATE_REQUEST_STATUS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};

        dispatch({
          type: UPDATE_REQUEST_STATUS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_REQUEST_STATUS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const adjustAmount = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: 'FETCH_ADJUST_AMOUNT'});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: 'transin/adjust-amount',
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: 'ADJUST_AMOUNT', payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'ADJUST_AMOUNT_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'ADJUST_AMOUNT_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const resetPage = () => {
  return async (dispatch) => {
    dispatch({type: RESET_PAGE});
  };
};

export const hideTerminatedTrip = (isCrossDock = false) => {
  return async (dispatch) => {
    if (isCrossDock) {
      dispatch({type: HIDE_CROSSDOCK_TERMINATED_TRIP});
    } else {
      dispatch({type: HIDE_TERMINATED_TRIP});
    }
  };
};

const updateOtpVerification = (state, payload) => {
  const newAdvancePayment = {
    ...state?.tripAdvanceData?.advance_payment_detail,
  };

  const updateTripCharges = {
    ...payload.trip_charges,
  };

  const afterVerify = {
    ...state?.tripAdvanceData,
    trip_charges: updateTripCharges,
  };

  newAdvancePayment.otp_verified = true;
  const newTripAdvanceData = {
    // ...state?.tripAdvanceData,
    ...afterVerify,
  };
  newTripAdvanceData.advance_payment_detail = newAdvancePayment;
  return {
    ...state,
    verifyingOTP: false,
    tripAdvanceData: newTripAdvanceData,
  };
};

const updateCrossdockOtpVerification = (state, payload) => {
  const newAdvancePayment = {
    ...state?.tripAdvanceData?.crossdock_advance_payment_detail,
  };
  newAdvancePayment.otp_verified = true;
  const newTripAdvanceData = {
    ...state?.tripAdvanceData,
  };
  newTripAdvanceData.crossdock_advance_payment_detail = newAdvancePayment;
  return {
    ...state,
    verifyingOTP: false,
    tripAdvanceData: newTripAdvanceData,
  };
};

const updateCrossDockTerminatedTrip = (state, payload) => {
  const newAdvancePayment = {
    ...state?.tripAdvanceData?.crossdock_advance_payment_detail,
  };
  newAdvancePayment.terminated_trip_advance_adjustment = [];
  newAdvancePayment.terminated_trip_advance_adjustment_diesel = [];
  const newTripAdvanceData = {
    ...state?.tripAdvanceData,
  };
  newTripAdvanceData.crossdock_advance_payment_detail = newAdvancePayment;
  return {
    ...state,
    tripAdvanceData: newTripAdvanceData,
  };
};

const updateTerminatedTrip = (state, payload) => {
  const newAdvancePayment = {
    ...state?.tripAdvanceData?.advance_payment_detail,
  };
  newAdvancePayment.terminated_trip_advance_adjustment = [];
  newAdvancePayment.terminated_trip_advance_adjustment_diesel = [];
  const newTripAdvanceData = {
    ...state?.tripAdvanceData,
  };
  newTripAdvanceData.advance_payment_detail = newAdvancePayment;
  return {
    ...state,
    tripAdvanceData: newTripAdvanceData,
  };
};

const updateBeneficiaries = (state, payload) => {
  const newTripAdvanceData = {
    ...state?.tripAdvanceData,
  };
  newTripAdvanceData.beneficiary_data = payload.beneficiary_data;
  newTripAdvanceData.beneficiary_ids = payload.beneficiary_ids;
  return {
    ...state,
    tripAdvanceData: newTripAdvanceData,
  };
};

// const updateTerminatedTrip = (state, payload) => {
//   const newAdvancePayment = state?.advancePayment;
//   if (newAdvancePayment !== undefined) {
//     newAdvancePayment.terminated_trip_advance_adjustment = [];
//     newAdvancePayment.terminated_trip_advance_adjustment_diesel = [];
//   }
//   return {...state, advancePayment: newAdvancePayment};
// };

export const sendOTP = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SENDING_OTP});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Finance.sendOTPURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: SENDING_OTP_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: SENDING_OTP_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SENDING_OTP_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const verifyOTP = (requestData, isCrossDock = false) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: VERIFY_OTP});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Finance.verifyOTPURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        if (isCrossDock) {
          dispatch({type: CROSSDOCK_VERIFY_OTP_COMPLETED, payload: data});
        } else {
          dispatch({type: VERIFY_OTP_COMPLETED, payload: data});
        }
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: VERIFY_OTP_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: VERIFY_OTP_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const addBeneficiary = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: ADD_BENEFICIARY});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Finance.addBeneficiaryURL(),
        data: requestData,
      });
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: ADD_BENEFICIARY_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: ADD_BENEFICIARY_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: ADD_BENEFICIARY_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_TRIP_ADVANCE:
      return {
        tripChargeId: null,
        isFetching: true,
        tripId: null,
        vendorId: null,
        tripAdvanceData: {},
      };
    case FETCH_TRIP_ADVANCE_COMPLETED:
      return {
        ...state,
        tripAdvanceData: payload,
        isFetching: false,
        tripChargeId: payload.trip_charge_id,
        tripId: payload.trip_id,
        vendorId: payload.vendor_id,
        check_box_flag: payload.check_box_flag,
      };
    case FETCH_TRIP_ADVANCE_FAILED:
      return {
        tripChargeId: null,
        isFetching: false,
        tripId: null,
        vendorId: null,
        tripAdvanceData: {},
      };

    case 'FETCH_ADJUST_AMOUNT': {
      return {
        ...state,
        fetchAdjustAmount: true,
      };
    }

    case 'ADJUST_AMOUNT_FAILED': {
      return {
        ...state,
        fetchAdjustAmount: false,
      };
    }

    case 'ADJUST_AMOUNT': {
      console.log('state', state);

      let AllData = {
        ...state.tripAdvanceData,
      };

      let addUpdateBalanceToPay = {
        ...state.tripAdvanceData.advance_payment_detail,
        balance_remaining: payload.balance_remaining,
      };

      const afterUpdateBalance = {
        ...AllData,
        advance_payment_detail: addUpdateBalanceToPay,
      };

      console.log('after update balance', afterUpdateBalance);

      return {
        ...state,
        tripAdvanceData: {
          ...afterUpdateBalance,
        },
        fetchAdjustAmount: false,
      };
    }
    //add a new advance
    case ADD_NEW_ADVANCE:
      return {
        ...state,
        addingAdvance: true,
      };
    case ADD_NEW_ADVANCE_COMPLETED:
      return {...state, addingAdvance: false, tripAdvanceData: payload};
    case UPDATE_REQUEST_STATUS_COMPLETED:
      return {...state, tripAdvanceData: payload};
    case ADD_NEW_ADVANCE_FAILED:
      return {...state, addingAdvance: false};

    case UPDATE_TRIP_CHARGE:
      return {...state, updatingTripCharge: true};
    case UPDATE_TRIP_CHARGE_COMPLETED:
      return {
        ...state,
        updatingTripCharge: false,
        tripAdvanceData: payload,
        advancePayment: payload?.advance_payment_detail,
        crossDockAdvancePaymentDetail:
          payload?.crossdock_advance_payment_detail,
        tripChargeId: payload.trip_charge_id,
      };
    case ADD_BENEFICIARY_COMPLETED:
      return updateBeneficiaries(state, payload);
    case UPDATE_TRIP_CHARGE_FAILED:
      return {...state, updatingTripCharge: false};
    case SENDING_OTP:
      return {...state, sendingOTP: true};
    case SENDING_OTP_COMPLETED:
    case SENDING_OTP_FAILED:
      return {...state, sendingOTP: false};
    case VERIFY_OTP:
      return {...state, verifyingOTP: true};
    case VERIFY_OTP_COMPLETED:
      return updateOtpVerification(state, payload);
    case CROSSDOCK_VERIFY_OTP_COMPLETED:
      return updateCrossdockOtpVerification(state, payload);
    case VERIFY_OTP_FAILED:
      return {...state, verifyingOTP: false};

    case HIDE_TERMINATED_TRIP:
      return updateTerminatedTrip(state, payload);
    case HIDE_CROSSDOCK_TERMINATED_TRIP:
      return updateCrossDockTerminatedTrip(state, payload);
    case FETCH_TRIP_CHARGES:
    case FETCH_TRIP_CHARGES_FAILED:
      return {
        ...state,
        tripsWithoutCharges: [],
        tripsWithCharges: [],
        daysLimit: 20,
      };
    case FETCH_TRIP_CHARGES_COMPLETED:
      return {
        ...state,
        tripsWithoutCharges: payload.trips_without_charges,
        tripsWithCharges: payload.trips_with_charges,
        daysLimit: payload.days_limit,
      };

    case RESET_PAGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
