import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Reports} from '../../../helpers/Urls';

const intialState = {
  isReportFetching: false,
  vehicleStatusSummaryReport: {},
  isReportDetailFetching: false,
  isDeleteReportDetailFetching: false,
  vehicleStatusSummaryReportDetail: {},
  categoryType: '',
};

export const FETCH_VEHICLE_STATUS_SUMMARY_REPORT =
  'FETCH_VEHICLE_STATUS_SUMMARY_REPORT';
export const FETCH_VEHICLE_STATUS_SUMMARY_REPORT_COMPLETED =
  'FETCH_VEHICLE_STATUS_SUMMARY_REPORT_COMPLETED';
export const FETCH_VEHICLE_STATUS_SUMMARY_REPORT_FAILED =
  'FETCH_VEHICLE_STATUS_SUMMARY_REPORT_FAILED';

export const FETCH_VEHICLE_STATUS_SUMMARY_REPORT_DETAIL =
  'FETCH_VEHICLE_STATUS_SUMMARY_REPORT_DETAIL';
export const FETCH_VEHICLE_STATUS_SUMMARY_REPORT_DETAIL_COMPLETED =
  'FETCH_VEHICLE_STATUS_SUMMARY_REPORT_DETAIL_COMPLETED';
export const FETCH_VEHICLE_STATUS_SUMMARY_REPORT_DETAIL_FAILED =
  'FETCH_VEHICLE_STATUS_SUMMARY_REPORT_DETAIL_FAILED';

export const APPLY_CATEGORY = 'APPLY_CATEGORY';

const DELETE_VEHICLE = 'DELETE_VEHICLE';
const DELETE_VEHICLE_COMPLETED = 'DELETE_VEHICLE_COMPLETED';
const DELETE_VEHICLE_FAILED = 'DELETE_VEHICLE_FAILED';

export const applyCategory = (category = 'con') => {
  return (dispatch) => {
    dispatch({
      type: APPLY_CATEGORY,
      payload: category,
    });
  };
};

export const fetchVehicleStatusSummaryReport = (category) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_VEHICLE_STATUS_SUMMARY_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Reports.fetchVehicleStatusSummaryReportURL(category),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_VEHICLE_STATUS_SUMMARY_REPORT_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_VEHICLE_STATUS_SUMMARY_REPORT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_VEHICLE_STATUS_SUMMARY_REPORT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const deleteVehicleRecord = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DELETE_VEHICLE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Reports.deleteVehicleRecordURL(),
        data: requestData,
      });
      const {data, status} = response || {};

      if (status && !data.url) {
        dispatch({
          type: DELETE_VEHICLE_COMPLETED,
          // payload: data,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DELETE_VEHICLE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DELETE_VEHICLE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchVehicleStatusSummaryReportDetail = (filters) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_VEHICLE_STATUS_SUMMARY_REPORT_DETAIL});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Reports.fetchVehicleStatusSummaryReportDetailURL(filters),
      });
      const {data, status} = response || {};
      if (status && !data.url) {
        dispatch({
          type: FETCH_VEHICLE_STATUS_SUMMARY_REPORT_DETAIL_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_VEHICLE_STATUS_SUMMARY_REPORT_DETAIL_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_VEHICLE_STATUS_SUMMARY_REPORT_DETAIL_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = intialState, action) => {
  const {type, payload = {}} = action || {};
  switch (type) {
    case FETCH_VEHICLE_STATUS_SUMMARY_REPORT:
      return {
        ...state,
        isReportFetching: true,
      };

    //...............delete-record..............
    case DELETE_VEHICLE:
      return {
        ...state,
        // isReportDetailFetching: true,
        isDeleteReportDetailFetching: true,
      };
    case DELETE_VEHICLE_COMPLETED:
      Object.values(
        state.vehicleStatusSummaryReportDetail.final_32_sxl.Available.data,
      ).map((dt) => {
        Object.values(payload.vehicles).map((it) => {
          if (dt.vehicle_number == it.basicInfo.vehicle_number) {
            dt.unavailable_record_id = it.basicInfo.unavailable_record_id;
            dt.unavailable_from = it.basicInfo.unavailable_from;
            dt.unavailable_till = it.basicInfo.unavailable_till;
          }
        });
      });
      return {
        ...state,
        // isReportDetailFetching: false,
        isDeleteReportDetailFetching: false,
        // vehicleStatusSummaryReportDetail: payload,
      };
    case DELETE_VEHICLE_FAILED:
      return {
        ...state,
        // isReportDetailFetching: false,
        isDeleteReportDetailFetching: false,
      };
    //...............delete-record..............

    case FETCH_VEHICLE_STATUS_SUMMARY_REPORT_COMPLETED:
      return {
        ...state,
        isReportFetching: false,
        vehicleStatusSummaryReport: payload,
      };
    case FETCH_VEHICLE_STATUS_SUMMARY_REPORT_FAILED:
      return {
        ...state,
        isReportFetching: false,
      };
    case FETCH_VEHICLE_STATUS_SUMMARY_REPORT_DETAIL:
      return {
        ...state,
        isReportDetailFetching: true,
      };
    case FETCH_VEHICLE_STATUS_SUMMARY_REPORT_DETAIL_COMPLETED:
      return {
        ...state,
        isReportDetailFetching: false,
        vehicleStatusSummaryReportDetail: payload,
      };
    case FETCH_VEHICLE_STATUS_SUMMARY_REPORT_DETAIL_FAILED:
      return {
        ...state,
        isReportDetailFetching: false,
      };
    case APPLY_CATEGORY:
      return {
        ...state,
        categoryType: payload,
      };
    default:
      return state;
  }
};
