import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Tracking} from '../../../helpers/Urls';

export const FETCH_TRACKING_DETAILS = 'FETCH_TRACKING_DETAILS';
export const FETCH_TRACKING_DETAILS_COMPLETED =
  'FETCH_TRACKING_DETAILS_COMPLETED';
export const FETCH_TRACKING_DETAILS_FAILED = 'FETCH_TRACKING_DETAILS_FAILED';
export const APPLY_TRACKING_FILTER = 'APPLY_TRACKING_FILTER';
export const CLEAR_TRACKING_FILTER = 'CLEAR_TRACKING_FILTER';

export const FETCH_TRIP_CODES = 'FETCH_TRIP_CODES';
export const FETCH_TRIP_CODES_COMPLETED = 'FETCH_TRIP_CODES_COMPLETED';
export const FETCH_TRIP_CODES_FAILED = 'FETCH_TRIP_CODES_FAILED';

export const TRIP_CODE_REPORT = 'TRIP_CODE_REPORT';
export const TRIP_CODE_REPORT_COMPLETED = 'TRIP_CODE_REPORT_COMPLETED';
export const TRIP_CODE_REPORT_FAILED = 'TRIP_CODE_REPORT_FAILED';

const FILTER_TRACKING_DETAILS_FALSE = 'FILTER_TRACKING_DETAILS_FALSE';
const FILTER_TRACKING_DETAILS_TRUE = 'FILTER_TRACKING_DETAILS_TRUE';
const SET_CURRENT_TRACKING_DATA = 'SET_CURRENT_TRACKING_DATA';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  error: '',
  gpsIds: [],
  recentIds: [],
  counts: {},
  vendor_filter_data: [],
};

export const applyFilter = (filter = {}, tripStatus = '') => {
  return (dispatch) => {
    dispatch({
      type: APPLY_TRACKING_FILTER,
      payload: filter,
    });
  };
};

export const clearFilter = (filter = {}) => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_TRACKING_FILTER,
    });
  };
};

export const filteredData = (searchInput = '', filteredTrips = []) => (
  dispatch,
) => {
  console.log('filter tracking called');
  if (!searchInput) {
    dispatch({
      type: FILTER_TRACKING_DETAILS_FALSE,
    });
    return;
  }
  dispatch({
    type: FILTER_TRACKING_DETAILS_TRUE,
    payload: searchInput,
  });
};

export const currentTableData = (tableData = []) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_TRACKING_DATA,
    payload: tableData,
  });
};

export const fetchTrackingDetails = (isAll = false, search = '') => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TRACKING_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Tracking.fetchTrackingDetailsURL(isAll) + search,
        params: {
          ...(isAll && {all: true}),
        },
      });
      console.log('response --FETCH_TRACKING_DETAILS--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_TRACKING_DETAILS_COMPLETED,
          payload: {...data, isAll},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_TRACKING_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TRACKING_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchTripCodeOptions = (searchText) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TRIP_CODES});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Tracking.fetchTripCodeOptionsURL(searchText),
      });
      console.log('response --FETCH_TRACKING_DETAILS--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_TRIP_CODES_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_TRIP_CODES_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TRIP_CODES_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const getTripCodesReport = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: TRIP_CODE_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Tracking.getTripCodesReportURL(),
        data: requestData,
      });
      console.log('response --FETCH_TRACKING_DETAILS--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: TRIP_CODE_REPORT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: TRIP_CODE_REPORT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: TRIP_CODE_REPORT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_TRACKING_DETAILS:
    case TRIP_CODE_REPORT:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
        searching: '',
        // filteredTrips: null,
      };
    case FETCH_TRACKING_DETAILS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        searching: '',
        // filteredTrips: null,
        gpsIds: payload.isAll ? payload.vehicle_gps_data_ids : [],
        recentIds: payload.isAll ? [] : payload.vehicle_gps_data_ids,
        counts: payload.Counts,
        vendor_filter_data: payload.vendor_filter_data,
        filter: payload.isAll ? {} : {...state.filter},
      };
    case FETCH_TRACKING_DETAILS_FAILED:
    case TRIP_CODE_REPORT_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
        searching: '',
        // filteredTrips: null,
      };
    case SET_CURRENT_TRACKING_DATA:
      return {
        ...state,
        currentTableData: payload,
      };
    case FILTER_TRACKING_DETAILS_FALSE:
      return {
        ...state,
        searching: '',
        // filteredTrips: null,
      };
    case FILTER_TRACKING_DETAILS_TRUE:
      return {
        ...state,
        searching: payload,
        // filteredTrips: payload,
      };
    case APPLY_TRACKING_FILTER:
      // localStorage.setItem('trip-filter', JSON.stringify(payload));
      return {
        ...state,
        filter: payload,
      };
    case CLEAR_TRACKING_FILTER:
      return {
        ...state,
        filter: {},
      };
    case TRIP_CODE_REPORT_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        error: '',
      };

    default:
      return state;
  }
};
