import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Vendors} from '../../../helpers/Urls';
import UseAbortApiCall from '../../../Common/Hooks/use-abort';
import axios from 'axios';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  sendingOTP: false,
  error: '',
  vendorIds: [],
  basicCount: 0,
  financeCount: 0,
  resetPage: false,
  isRequesting: false,
  canApproveFinPermission: false,
};

const FETCH_VENDOR_DETAILS = 'FETCH_VENDOR_DETAILS';
const FETCH_VENDOR_DETAILS_COMPLETED = 'FETCH_VENDOR_DETAILS_COMPLETED';
const FETCH_VENDOR_DETAILS_FAILED = 'FETCH_VENDOR_DETAILS_FAILED';

const APPROVE_VENDOR = 'APPROVE_VENDOR';
const APPROVE_VENDOR_COMPLETED = 'APPROVE_VENDOR_COMPLETED';
const APPROVE_VENDOR_FAILED = 'APPROVE_VENDOR_FAILED';
const SWITCH_TAB = 'SWITCH_TAB';

const RESET_PAGE = 'RESET_PAGE';

let temp = false;

export const switchTab = (warning_active_tab = null) => {
  return (dispatch) => {
    dispatch({type: SWITCH_TAB, payload: {warning_active_tab}});
  };
};

export const fetchVendorDetails = (
  isFinance = false,
  page,
  search,
  resetPage = true,
) => {
  return async (dispatch) => {
    let response = {};
    let {cancelApiCall} = UseAbortApiCall('cancelPreviousRequestFetchDoList');
    try {
      dispatch({type: FETCH_VENDOR_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        cancelToken: cancelApiCall(),
        url: isFinance
          ? Vendors.fetchVendorFinanceDetailsURl(page, search)
          : Vendors.fetchVendorDetailsURL(page, search),
      });
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }
      if (status === true) {
        dispatch({type: FETCH_VENDOR_DETAILS_COMPLETED, payload: data});
        return response;
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_VENDOR_DETAILS_FAILED,
          payload: {error: message},
        });
        return {error: true};
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return {error: true};
      }
      dispatch({
        type: FETCH_VENDOR_DETAILS_FAILED,
        payload: {error: err.message},
      });
      return {error: true};
    }
  };
};

//action= APPROVAL_ACTIONS.APPROVE, customer_id
export const approveVendor = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: APPROVE_VENDOR});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Vendors.approveVendorURL(),
        data: requestData,
      });
      console.log('response --APPROVE_VENDOR--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: APPROVE_VENDOR_COMPLETED, payload: data});
      } else {
        const {message: error = 'Error'} = response || {};
        dispatch({type: APPROVE_VENDOR_FAILED, payload: {error}});
      }
    } catch (err) {
      dispatch({
        type: APPROVE_VENDOR_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_VENDOR_DETAILS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };

    case SWITCH_TAB:
      return {
        warning_active_tab: payload.warning_active_tab,
      };
    case FETCH_VENDOR_DETAILS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        vendorIds: payload?.vendors_list,
        basicCount: payload?.basic_count,
        financeCount: payload?.finance_count,
        total: payload?.total,
        search: payload.query_string,
        canApproveFinPermission: payload?.approve_vendor_fin,
      };
    case FETCH_VENDOR_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
        vendorIds: [],
      };
    case RESET_PAGE:
      return {
        ...state,
        resetPage: !state.resetPage,
      };
    case APPROVE_VENDOR:
      return {...state, isRequesting: true};
    case APPROVE_VENDOR_COMPLETED:
    case APPROVE_VENDOR_FAILED:
      return {...state, isRequesting: false};
    default:
      return state;
  }
};
