import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Vendors} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  sendingOTP: false,
  error: '',
};

const VERIFY_VENDOR = 'VERIFY_VENDOR';
const VERIFY_VENDOR_COMPLETED = 'VERIFY_VENDOR_COMPLETED';
const VERIFY_VENDOR_FAILED = 'VERIFY_VENDOR_FAILED';

const SEND_OTP = 'SEND_OTP';
const SEND_OTP_COMPLETED = 'SEND_OTP_COMPLETED';
const SEND_OTP_FAILED = 'SEND_OTP_FAILED';

export const sendOTP = (vendor_id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SEND_OTP});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Vendors.sendOTPURL(),
        data: {vendor_id},
      });
      console.log('response --SEND_OTP--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: SEND_OTP_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: SEND_OTP_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({type: SEND_OTP_FAILED, payload: {error: err.message}});
    }
    return response;
  };
};

export const verifyVendor = (vendor_id, otp) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: VERIFY_VENDOR});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Vendors.verifyVendorURL(),
        data: {vendor_id, otp},
      });
      console.log('response --VERIFY_VENDOR_COMPLETED--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: VERIFY_VENDOR_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({type: VERIFY_VENDOR_FAILED, payload: {error: message}});
      }
    } catch (err) {
      dispatch({type: VERIFY_VENDOR_FAILED, payload: {error: err.message}});
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case VERIFY_VENDOR:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case VERIFY_VENDOR_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
      };
    case VERIFY_VENDOR_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
      };
    case SEND_OTP:
      return {...state, sendingOTP: true};
    case SEND_OTP_COMPLETED:
    case SEND_OTP_FAILED:
      return {...state, sendingOTP: false};
    default:
      return state;
  }
};
