import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Cost} from '../../../helpers/Urls';

const INITIAL_STATE = {
  creatingBaseRate: false,
  isDownloading: false,
  filters: {},
  isFetchingBaseRates: false,
  isFetchingBaseRateInitial: false,
  isFetchingBaseRateId: false,
  baseRateVendor: {},
  costRate: {},
  vendors: {},
  costRateIds: [],
  currentBaseRateFilterData: null,
  searching: '',
  vehiclesTypeDataObj: {},
};

const CREATE_VENDOR_BASE_RATE = 'CREATE_VENDOR_BASE_RATE';
const CREATE_VENDOR_BASE_RATE_COMPLETED = 'CREATE_VENDOR_BASE_RATE_COMPLETED';
const CREATE_VENDOR_BASE_RATE_FAILED = 'CREATE_VENDOR_BASE_RATE_FAILED';

const FETCH_VENDOR_BASE_RATE_BY_ID = 'FETCH_VENDOR_BASE_RATE_BY_ID';
const FETCH_VENDOR_BASE_RATE_BY_ID_COMPLETED =
  'FETCH_VENDOR_BASE_RATE_BY_ID_COMPLETED';
const FETCH_VENDOR_BASE_RATE_BY_ID_FAILED =
  'FETCH_VENDOR_BASE_RATE_BY_ID_FAILED';

const FETCH_VENDOR_BASE_RATES = 'FETCH_VENDOR_BASE_RATES';
const FETCH_VENDOR_BASE_RATES_COMPLETED = 'FETCH_VENDOR_BASE_RATES_COMPLETED';
const FETCH_VENDOR_BASE_RATES_FAILED = 'FETCH_VENDOR_BASE_RATES_FAILED';

const FETCH_VENDOR_BASE_RATE_INITIAL = 'FETCH_VENDOR_BASE_RATE_INITIAL';
const FETCH_VENDOR_BASE_RATE_INITIAL_COMPLETED =
  'FETCH_VENDOR_BASE_RATE_INITIAL_COMPLETED';
const FETCH_VENDOR_BASE_RATE_INITIAL_FAILED =
  'FETCH_VENDOR_BASE_RATE_INITIAL_FAILED';

export const EDIT_VENDOR_BASE_RATE = 'EDIT_VENDOR_BASE_RATE';
export const EDIT_VENDOR_BASE_RATE_COMPLETED =
  'EDIT_VENDOR_BASE_RATE_COMPLETED';
export const EDIT_VENDOR_BASE_RATE_FAILED = 'EDIT_VENDOR_BASE_RATE_FAILED';

const DOWNLOAD_VENDOR_BASE_RATE_REPORT = 'DOWNLOAD_VENDOR_BASE_RATE_REPORT';
const DOWNLOAD_VENDOR_BASE_RATE_REPORT_COMPLETED =
  'DOWNLOAD_VENDOR_BASE_RATE_REPORT_COMPLETED';
const DOWNLOAD_VENDOR_BASE_RATE_REPORT_FAILED =
  'DOWNLOAD_VENDOR_BASE_RATE_REPORT_FAILED';

const RESET_PAGE = 'RESET_PAGE';
const SET_CURRENT_VENDOR_BASE_RATE_FILTER_DATA =
  'SET_CURRENT_VENDOR_BASE_RATE_FILTER_DATA';
const FILTER_VENDOR_BASE_RATE_FALSE = 'FILTER_VENDOR_BASE_RATE_FALSE';
const FILTER_VENDOR_BASE_RATE_TRUE = 'FILTER_VENDOR_BASE_RATE_TRUE';

export const createVendorBaseRate = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CREATE_VENDOR_BASE_RATE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Cost.createVendorBaseRateURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: CREATE_VENDOR_BASE_RATE_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CREATE_VENDOR_BASE_RATE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CREATE_VENDOR_BASE_RATE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const downloadVendorBaseRateReport = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_VENDOR_BASE_RATE_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Cost.downloadVendorBaseRateReportURL(),
        data: {...requestData},
      });
      console.log('response --DOWNLOAD_VENDOR_BASE_RATE_REPORT--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: DOWNLOAD_VENDOR_BASE_RATE_REPORT_COMPLETED,
          payload: data,
        });
      } else {
        const {message: error = 'Error'} = response || {};
        dispatch({
          type: DOWNLOAD_VENDOR_BASE_RATE_REPORT_FAILED,
          payload: {error},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_VENDOR_BASE_RATE_REPORT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const vendorBaseRateFilterData = (data = '') => (dispatch) => {
  dispatch({
    type: SET_CURRENT_VENDOR_BASE_RATE_FILTER_DATA,
    payload: data,
  });
};

export const filteredData = (searchInput = '') => (dispatch) => {
  console.log('report pod called');
  if (!searchInput) {
    dispatch({
      type: FILTER_VENDOR_BASE_RATE_FALSE,
    });
    return;
  }
  dispatch({
    type: FILTER_VENDOR_BASE_RATE_TRUE,
    payload: searchInput,
  });
};

export const fetchVendorBaseRates = (page, search, resetPage = true) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_VENDOR_BASE_RATES});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Cost.fetchVendorBaseRatesURL(page, search),
      });
      // console.log('response --FETCH_VENDOR_DETAILS_COMPLETED--', response);
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }

      if (status === true) {
        dispatch({type: FETCH_VENDOR_BASE_RATES_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_VENDOR_BASE_RATES_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_VENDOR_BASE_RATES_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchVendorBaseRateInitial = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_VENDOR_BASE_RATE_INITIAL});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Cost.fetchVendorBaseRateInitialURL(),
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_VENDOR_BASE_RATE_INITIAL_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_VENDOR_BASE_RATE_INITIAL_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_VENDOR_BASE_RATE_INITIAL_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchVendorBaseRateById = (baseRateId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_VENDOR_BASE_RATE_BY_ID});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Cost.fetchVendorBaseRateByIdURL(baseRateId),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: FETCH_VENDOR_BASE_RATE_BY_ID_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_VENDOR_BASE_RATE_BY_ID_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_VENDOR_BASE_RATE_BY_ID_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const editVendorBaseRate = (baseRateId, requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: EDIT_VENDOR_BASE_RATE});
      response = await doRequest({
        method: REQUEST_TYPE.PATCH,
        url: Cost.editVendorBaseRateURL(baseRateId),
        data: requestData,
      });
      console.log('response --EDIT_VENDOR_BASE_RATE--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({type: EDIT_VENDOR_BASE_RATE_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: EDIT_VENDOR_BASE_RATE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: EDIT_VENDOR_BASE_RATE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case CREATE_VENDOR_BASE_RATE:
      return {...state, creatingBaseRate: true};
    case CREATE_VENDOR_BASE_RATE_COMPLETED:
      return {
        ...state,
        creatingBaseRate: false,
      };
    case CREATE_VENDOR_BASE_RATE_FAILED:
      return {...state, creatingBaseRate: false};
    case FETCH_VENDOR_BASE_RATE_BY_ID:
      return {...state, isFetchingBaseRateId: true};
    case FETCH_VENDOR_BASE_RATE_BY_ID_COMPLETED:
      return {
        ...state,
        isFetchingBaseRateId: false,
      };
    case FETCH_VENDOR_BASE_RATE_BY_ID_FAILED:
      return {...state, isFetchingBaseRateId: false};
    case DOWNLOAD_VENDOR_BASE_RATE_REPORT:
      return {...state, isDownloading: true};
    case DOWNLOAD_VENDOR_BASE_RATE_REPORT_COMPLETED:
      return {
        ...state,
        isDownloading: false,
      };
    case DOWNLOAD_VENDOR_BASE_RATE_REPORT_FAILED:
      return {...state, isDownloading: false};
    case SET_CURRENT_VENDOR_BASE_RATE_FILTER_DATA: {
      return {
        ...state,
        currentBaseRateFilterData: payload,
      };
    }
    case FETCH_VENDOR_BASE_RATES:
      return {
        ...state,
        isFetchingBaseRates: true,
        hasError: false,
        error: '',
      };
    case FETCH_VENDOR_BASE_RATES_COMPLETED:
      return {
        ...state,
        isFetchingBaseRates: false,
        costRate: payload?.cost_base_rate,
        costRateIds: payload?.cost_base_rate_ids,
        vendors: payload?.vendors,
        total: payload?.total,
        filters: payload?.filters,
        hasError: false,
      };
    case FETCH_VENDOR_BASE_RATES_FAILED:
      return {
        ...state,
        isFetchingBaseRates: false,
        hasError: true,
        error: payload.error,
      };
    case FETCH_VENDOR_BASE_RATE_INITIAL:
      return {
        ...state,
        isFetchingBaseRateInitial: true,
        hasError: false,
        error: '',
      };
    case FETCH_VENDOR_BASE_RATE_INITIAL_COMPLETED:
      return {
        ...state,
        isFetchingBaseRateInitial: false,
        vendors: payload?.vendor_data,
        vendorAllData: payload,
        hasError: false,
        vehiclesTypeDataObj: payload?.vehicle_type,
      };
    case FETCH_VENDOR_BASE_RATE_INITIAL_FAILED:
      return {
        ...state,
        isFetchingBaseRateInitial: false,
        hasError: true,
        error: payload.error,
      };
    case FILTER_VENDOR_BASE_RATE_FALSE:
      return {
        ...state,
        searching: '',
      };
    case FILTER_VENDOR_BASE_RATE_TRUE:
      return {
        ...state,
        searching: payload,
      };
    case RESET_PAGE:
      return {
        ...state,
        resetPage: !state.resetPage,
      };

    default:
      return state;
  }
};
