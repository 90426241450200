import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Bookings} from '../../../helpers/Urls';

const INITIAL_STATE = {isCloseBidFetching: false, isSendBoardFetching: false};

export const SEND_TO_LOAD_BOARD = 'SEND_TO_LOAD_BOARD';
export const SEND_TO_LOAD_BOARD_COMPLETED = 'SEND_TO_LOAD_BOARD_COMPLETED';
export const SEND_TO_LOAD_BOARD_FAILED = 'SEND_TO_LOAD_BOARD_FAILED';

export const CLOSE_BID = 'CLOSE_BID';
export const CLOSE_BID_COMPLETED = 'CLOSE_BID_COMPLETED';
export const CLOSE_BID_FAILED = 'CLOSE_BID_FAILED';

export const sendToLoadBoard = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SEND_TO_LOAD_BOARD});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Bookings.sendToLoadBoardURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: SEND_TO_LOAD_BOARD_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: SEND_TO_LOAD_BOARD_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SEND_TO_LOAD_BOARD_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const closeBid = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CLOSE_BID});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Bookings.closeBidURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: CLOSE_BID_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CLOSE_BID_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CLOSE_BID_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case SEND_TO_LOAD_BOARD:
      return {
        ...state,
        isSendBoardFetching: true,
      };
    case CLOSE_BID:
      return {...state, isCloseBidFetching: true};
    case SEND_TO_LOAD_BOARD_COMPLETED:
      return {
        ...state,
        isSendBoardFetching: false,
      };
    case CLOSE_BID_COMPLETED:
      return {...state, isCloseBidFetching: false};
    case SEND_TO_LOAD_BOARD_FAILED:
      return {
        ...state,
        isSendBoardFetching: false,
      };
    case CLOSE_BID_FAILED:
      return {...state, isCloseBidFetching: false};
    default:
      return state;
  }
};
