import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Tickets} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  isFetchingOpenTickets: false,
  isFetchingClosedTickets: false,
  isChangingTicketStatus: false,
  updatingTicket: false,
  filters: {},
  currentTicketFilterData: null,
  searching: '',
  openTicketsData: {},
  closedTicketsData: {},
  loadingTicketHistory: false,
  fetchingTicketDetails: false,
  ticketsSelectData: {},
  search: '',
};

const CREATE_TICKET = 'CREATE_TICKET';
const CREATE_TICKET_COMPLETED = 'CREATE_TICKET_COMPLETED';
const CREATE_TICKET_FAILED = 'CREATE_TICKET_FAILED';

const CHANGE_TICKET_STATUS = 'CHANGE_TICKET_STATUS';
const CHANGE_TICKET_STATUS_COMPLETED = 'CHANGE_TICKET_STATUS_COMPLETED';
const CHANGE_TICKET_STATUS_FAILED = 'CHANGE_TICKET_STATUS_FAILED';

const UPDATE_TICKET = 'UPDATE_TICKET';
const UPDATE_TICKET_COMPLETED = 'UPDATE_TICKET_COMPLETED';
const UPDATE_TICKET_FAILED = 'UPDATE_TICKET_FAILED';

const UPDATE_ESCALATION_LEVEL = 'UPDATE_ESCALATION_LEVEL';
const UPDATE_ESCALATION_LEVEL_COMPLETED = 'UPDATE_ESCALATION_LEVEL_COMPLETED';
const UPDATE_ESCALATION_LEVEL_FAILED = 'UPDATE_ESCALATION_LEVEL_FAILED';

const UPDATE_HALT_START_TIME = 'UPDATE_HALT_START_TIME';
const UPDATE_HALT_START_TIME_COMPLETED = 'UPDATE_HALT_START_TIME_COMPLETED';
const UPDATE_HALT_START_TIME_FAILED = 'UPDATE_HALT_START_TIME_FAILED';

const FETCH_TICKET_HISTORY = 'FETCH_TICKET_HISTORY';
const FETCH_TICKET_HISTORY_COMPLETED = 'FETCH_TICKET_HISTORY_COMPLETED';
const FETCH_TICKET_HISTORY_FAILED = 'FETCH_TICKET_HISTORY_FAILED';

const FETCH_TICKET_DETAILS_DATA = 'FETCH_TICKET_DETAILS_DATA';
const FETCH_TICKET_DETAILS_DATA_COMPLETED =
  'FETCH_TICKET_DETAILS_DATA_COMPLETED';
const FETCH_TICKET_DETAILS_DATA_FAILED = 'FETCH_TICKET_DETAILS_DATA_FAILED';

const FETCH_TICKET_SELECT = 'FETCH_TICKET_SELECT';
const FETCH_TICKET_SELECT_COMPLETED = 'FETCH_TICKET_SELECT_COMPLETED';
const FETCH_TICKET_SELECT_FAILED = 'FETCH_TICKET_SELECT_FAILED';

export const FETCH_OPEN_TICKETS = 'FETCH_OPEN_TICKETS';
export const FETCH_OPEN_TICKETS_COMPLETED = 'FETCH_OPEN_TICKETS_COMPLETED';
export const FETCH_OPEN_TICKETS_FAILED = 'FETCH_OPEN_TICKETS_FAILED';

export const FETCH_CLOSED_TICKETS = 'FETCH_CLOSED_TICKETS';
export const FETCH_CLOSED_TICKETS_COMPLETED = 'FETCH_CLOSED_TICKETS_COMPLETED';
export const FETCH_CLOSED_TICKETS_FAILED = 'FETCH_CLOSED_TICKETS_FAILED';

const RESET_PAGE = 'RESET_PAGE';
const SET_CURRENT_TICKET_FILTER_DATA = 'SET_CURRENT_TICKET_FILTER_DATA';
const FILTER_TICKET_FALSE = 'FILTER_TICKET_FALSE';
const FILTER_TICKET_TRUE = 'FILTER_TICKET_TRUE';

export const createTicket = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CREATE_TICKET});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Tickets.fetchTicketSelectURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: CREATE_TICKET_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CREATE_TICKET_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CREATE_TICKET_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const changeTicketStatus = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CHANGE_TICKET_STATUS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Tickets.changeTicketStatusURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: CHANGE_TICKET_STATUS_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CHANGE_TICKET_STATUS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CHANGE_TICKET_STATUS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const updateHaltStartTime = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: UPDATE_HALT_START_TIME});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Tickets.updateHaltStartTimeURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: UPDATE_HALT_START_TIME_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: UPDATE_HALT_START_TIME_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_HALT_START_TIME_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const updateEscalationLevel = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: UPDATE_ESCALATION_LEVEL});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Tickets.updateEscalationLevelURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: UPDATE_ESCALATION_LEVEL_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: UPDATE_ESCALATION_LEVEL_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_ESCALATION_LEVEL_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const updateTicket = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: UPDATE_TICKET});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Tickets.updateTicketURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: UPDATE_TICKET_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: UPDATE_TICKET_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_TICKET_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const ticketFilterData = (data = '') => (dispatch) => {
  dispatch({
    type: SET_CURRENT_TICKET_FILTER_DATA,
    payload: data,
  });
};

export const filteredTicketsData = (searchInput = '') => (dispatch) => {
  console.log('report pod called');
  if (!searchInput) {
    dispatch({
      type: FILTER_TICKET_FALSE,
    });
    return;
  }
  dispatch({
    type: FILTER_TICKET_TRUE,
    payload: searchInput,
  });
};

export const fetchTicketHistory = (tripId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TICKET_HISTORY});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Tickets.fetchTicketHistoryURL(tripId),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_TICKET_HISTORY_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_TICKET_HISTORY_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TICKET_HISTORY_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchTicketDetailsData = (tripId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TICKET_DETAILS_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Tickets.fetchTicketDetailsDataURL(tripId),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_TICKET_DETAILS_DATA_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_TICKET_DETAILS_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TICKET_DETAILS_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchTicketSelect = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TICKET_SELECT});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Tickets.fetchTicketSelectURL(),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_TICKET_SELECT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_TICKET_SELECT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TICKET_SELECT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchClosedTickets = (search) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_CLOSED_TICKETS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Tickets.fetchClosedTicketsURL(search),
      });
      const {data, status} = response || {};
      // if (resetPage) {
      //   dispatch({type: RESET_PAGE});
      // }
      if (status === true) {
        dispatch({
          type: FETCH_CLOSED_TICKETS_COMPLETED,
          payload: {...data, search},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_CLOSED_TICKETS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_CLOSED_TICKETS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchOpenTickets = (search) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_OPEN_TICKETS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Tickets.fetchOpenTicketsURL(search),
      });
      const {data, status} = response || {};
      // if (resetPage) {
      //   dispatch({type: RESET_PAGE});
      // }
      if (status === true) {
        dispatch({
          type: FETCH_OPEN_TICKETS_COMPLETED,
          payload: {...data, search},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_OPEN_TICKETS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_OPEN_TICKETS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case CREATE_TICKET:
      return {...state, isFetching: true};
    case CREATE_TICKET_COMPLETED:
      return {
        ...state,
        isFetching: false,
      };
    case CREATE_TICKET_FAILED:
      return {...state, isFetching: false};
    case CHANGE_TICKET_STATUS:
      return {...state, isChangingTicketStatus: true};
    case CHANGE_TICKET_STATUS_COMPLETED:
      return {
        ...state,
        isChangingTicketStatus: false,
      };
    case CHANGE_TICKET_STATUS_FAILED:
      return {...state, isChangingTicketStatus: false};
    case UPDATE_TICKET:
    case UPDATE_HALT_START_TIME:
    case UPDATE_ESCALATION_LEVEL:
      return {...state, updatingTicket: true};
    case UPDATE_HALT_START_TIME_COMPLETED:
    case UPDATE_TICKET_COMPLETED:
    case UPDATE_ESCALATION_LEVEL_COMPLETED:
      return {
        ...state,
        updatingTicket: false,
      };
    case UPDATE_HALT_START_TIME_FAILED:
    case UPDATE_TICKET_FAILED:
    case UPDATE_ESCALATION_LEVEL_FAILED:
      return {...state, updatingTicket: false};
    case SET_CURRENT_TICKET_FILTER_DATA: {
      return {
        ...state,
        currentTicketFilterData: payload,
      };
    }
    case FETCH_TICKET_HISTORY:
      return {
        ...state,
        loadingTicketHistory: true,
        hasError: false,
        error: '',
      };
    case FETCH_TICKET_HISTORY_COMPLETED:
      return {
        ...state,
        loadingTicketHistory: false,
        hasError: false,
      };
    case FETCH_TICKET_HISTORY_FAILED:
      return {
        ...state,
        loadingTicketHistory: false,
        hasError: true,
        error: payload.error,
      };
    case FETCH_TICKET_DETAILS_DATA:
      return {
        ...state,
        fetchingTicketDetails: true,
        hasError: false,
        error: '',
      };
    case FETCH_TICKET_DETAILS_DATA_COMPLETED:
      return {
        ...state,
        fetchingTicketDetails: false,
        hasError: false,
      };
    case FETCH_TICKET_DETAILS_DATA_FAILED:
      return {
        ...state,
        fetchingTicketDetails: false,
        hasError: true,
        error: payload.error,
      };
    case FETCH_TICKET_SELECT:
      return {
        ...state,
        loadingTicketSelect: true,
        hasError: false,
        error: '',
      };
    case FETCH_TICKET_SELECT_COMPLETED:
      return {
        ...state,
        loadingTicketSelect: false,
        ticketsSelectData: payload,
        hasError: false,
      };
    case FETCH_TICKET_SELECT_FAILED:
      return {
        ...state,
        loadingTicketSelect: false,
        hasError: true,
        error: payload.error,
      };
    case FETCH_OPEN_TICKETS:
      return {
        ...state,
        isFetchingOpenTickets: true,
        hasError: false,
        error: '',
      };
    case FETCH_OPEN_TICKETS_COMPLETED:
      return {
        ...state,
        isFetchingOpenTickets: false,
        openTicketsData: payload,
        search: payload?.search,
        filters: payload?.filters,
        hasError: false,
      };
    case FETCH_OPEN_TICKETS_FAILED:
      return {
        ...state,
        isFetchingOpenTickets: false,
        hasError: true,
        error: payload.error,
      };
    case FETCH_CLOSED_TICKETS:
      return {
        ...state,
        isFetchingClosedTickets: true,
        hasError: false,
        error: '',
      };
    case FETCH_CLOSED_TICKETS_COMPLETED:
      return {
        ...state,
        isFetchingClosedTickets: false,
        closedTicketsData: payload,
        search: payload?.search,
        filters: payload?.filters,
        hasError: false,
      };
    case FETCH_CLOSED_TICKETS_FAILED:
      return {
        ...state,
        isFetchingClosedTickets: false,
        hasError: true,
        error: payload.error,
      };
    case FILTER_TICKET_FALSE:
      return {
        ...state,
        searching: '',
      };
    case FILTER_TICKET_TRUE:
      return {
        ...state,
        searching: payload,
      };
    case RESET_PAGE:
      return {
        ...state,
        resetPage: !state.resetPage,
      };

    default:
      return state;
  }
};
