import {doRequest} from '../../helpers/network';
import {REQUEST_TYPE} from '../../constants';
import {merge} from 'lodash';
import {Pod} from '../../helpers/Urls';
const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  error: '',
  resetPage: false,
  searching: '',
};

const FETCH_POD_SUMMARY_REPORT = 'FETCH_POD_SUMMARY_REPORT';
const FETCH_POD_SUMMARY_REPORT_COMPLETED = 'FETCH_POD_SUMMARY_REPORT_COMPLETED';
const FETCH_POD_SUMMARY_REPORT_FAILED = 'FETCH_POD_SUMMARY_REPORT_FAILED';

const FETCH_POD_DETAILED_REPORT = 'FETCH_POD_DETAILED_REPORT';
const FETCH_POD_DETAILED_REPORT_COMPLETED =
  'FETCH_POD_DETAILED_REPORT_COMPLETED';
const FETCH_POD_DETAILED_REPORT_FAILED = 'FETCH_POD_DETAILED_REPORT_FAILED';

const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';
const DOWNLOAD_REPORT_COMPLETED = 'DOWNLOAD_REPORT_COMPLETED';
const DOWNLOAD_REPORT_FAILED = 'DOWNLOAD_REPORT_FAILED';

const FILTER_POD_DETAILS_FALSE = 'FILTER_POD_DETAILS_FALSE';
const FILTER_POD_DETAILS_TRUE = 'FILTER_POD_DETAILS_TRUE';
const SET_CURRENT_POD_DATA = 'SET_CURRENT_POD_DATA';

const SET_CURRENT_SUMMARY_FILTER_DATA = 'SET_CURRENT_SUMMARY_FILTER_DATA';
const SET_CURRENT_DETAIL_FILTER_DATA = 'SET_CURRENT_DETAIL_FILTER_DATA';

const UPDATE_SUMMARY_COLUMN_FALSE = 'UPDATE_SUMMARY_COLUMN_FALSE';
const UPDATE_SUMMARY_COLUMN_TRUE = 'UPDATE_SUMMARY_COLUMN_TRUE';

export const UPLOAD_POD_DOCUMENT = 'UPLOAD_POD_DOCUMENT';
export const UPLOAD_POD_DOCUMENT_COMPLETED = 'UPLOAD_POD_DOCUMENT_COMPLETED';
export const UPLOAD_POD_DOCUMENT_FAILED = 'UPLOAD_POD_DOCUMENT_FAILED';

const FETCH_POD_HISTORY = 'FETCH_POD_HISTORY';
const FETCH_POD_HISTORY_COMPLETED = 'FETCH_POD_HISTORY_COMPLETED';
const FETCH_POD_HISTORY_FAILED = 'FETCH_POD_HISTORY_FAILED';

const RESET_PAGE = 'RESET_PAGE';

export const fetchPodSummaryReport = (page, type, filter, resetPage = true) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_POD_SUMMARY_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Pod.getpodsummaryreportURL(page, type, filter),
      });
      console.log('response --FETCH_TRIPS_BY_STATUS--', response);

      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }
      if (status === true) {
        dispatch({
          type: FETCH_POD_SUMMARY_REPORT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_POD_SUMMARY_REPORT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_POD_SUMMARY_REPORT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const fetchPodDetailedReport = (page, filter, resetPage = true) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_POD_DETAILED_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Pod.getpoddetailreportURL(page, filter),
      });
      console.log('response --FETCH_TRIPS_BY_STATUS--', response);
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }
      if (status === true) {
        dispatch({
          type: FETCH_POD_DETAILED_REPORT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_POD_DETAILED_REPORT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_POD_DETAILED_REPORT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const downloadPodDetail = (url) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Pod.downloadPodDetailURL(url),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DOWNLOAD_REPORT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: DOWNLOAD_REPORT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_REPORT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const filteredData = (searchInput = '') => (dispatch) => {
  console.log('report pod called');
  if (!searchInput) {
    dispatch({
      type: FILTER_POD_DETAILS_FALSE,
    });
    return;
  }
  dispatch({
    type: FILTER_POD_DETAILS_TRUE,
    payload: searchInput,
  });
};
export const changeSummaryColumnData = (type = {}) => (dispatch) => {
  console.log('report pod called');
  if (!type) {
    dispatch({
      type: UPDATE_SUMMARY_COLUMN_FALSE,
    });
    return;
  }
  dispatch({
    type: UPDATE_SUMMARY_COLUMN_TRUE,
    payload: type,
  });
};

export const currentTableData = (tableData = []) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_POD_DATA,
    payload: tableData,
  });
};
export const summaryFilterData = (data = '') => (dispatch) => {
  dispatch({
    type: SET_CURRENT_SUMMARY_FILTER_DATA,
    payload: data,
  });
};
export const detailFilterData = (data = '') => (dispatch) => {
  dispatch({
    type: SET_CURRENT_DETAIL_FILTER_DATA,
    payload: data,
  });
};
export const uploadDocument = (requestData = {}, id = '') => {
  console.log('data', requestData);
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: UPLOAD_POD_DOCUMENT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Pod.uploadDocumentURL(id),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: UPLOAD_POD_DOCUMENT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: UPLOAD_POD_DOCUMENT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: UPLOAD_POD_DOCUMENT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const fetchPodHistory = (id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_POD_HISTORY});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Pod.getPodHistoryURL(id),
      });
      console.log('response --FETCH_TRIPS_BY_STATUS--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_POD_HISTORY_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_POD_HISTORY_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_POD_HISTORY_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
const setUpdatedPodDetail = (state = INITIAL_STATE, payload = {}) => {
  const {trips} = payload;
  if (trips) {
    const oldTrips = {...state.trips};
    const newTrips = {...trips};
    const updatedState = {...oldTrips, ...newTrips};
    return {...state, podDocRequesting: false, trips: updatedState};
  } else return state;
};
export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    case FETCH_POD_SUMMARY_REPORT:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
        // searching: '',
      };
    case FETCH_POD_SUMMARY_REPORT_COMPLETED:
      const {objects = {}} = payload;
      return {
        ...state,
        isFetching: false,
        hasError: false,
        objects,
        ...payload,
      };
    case FETCH_POD_SUMMARY_REPORT_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        searching: '',

        error: payload.message,
      };
    case FETCH_POD_DETAILED_REPORT:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_POD_DETAILED_REPORT_COMPLETED:
      const {
        trips = [],
        trip_ids = [],
        vehicle_types = '',
        customers = {},
        vehicles = {},
        vendors = {},
        total_pages,
      } = payload;
      return {
        ...state,
        isFetching: false,
        hasError: false,
        trips,
        trip_ids,
        vehicle_types,
        customers,
        vehicles,
        vendors,
        total: total_pages,
        ...payload,
      };
    case FETCH_POD_DETAILED_REPORT_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        searching: '',

        error: payload.message,
      };
    case SET_CURRENT_POD_DATA:
      return {
        ...state,
        currentTableData: payload,
      };
    case FILTER_POD_DETAILS_FALSE:
      return {
        ...state,
        searching: '',
        // filteredTrips: null,
      };
    case FILTER_POD_DETAILS_TRUE:
      return {
        ...state,
        searching: payload,
      };
    case UPDATE_SUMMARY_COLUMN_TRUE: {
      return {
        ...state,
        type: payload,
      };
    }
    case UPDATE_SUMMARY_COLUMN_FALSE: {
      return {
        ...state,
        type: '',
      };
    }
    case FETCH_POD_HISTORY: {
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    }
    case FETCH_POD_HISTORY_COMPLETED: {
      return {
        ...state,
        isFetching: false,
        hasError: false,
        error: '',
      };
    }
    case FETCH_POD_HISTORY_FAILED: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.message,
      };
    }
    case SET_CURRENT_SUMMARY_FILTER_DATA: {
      return {
        ...state,
        currentSummaryFilterData: payload,
      };
    }
    case SET_CURRENT_DETAIL_FILTER_DATA: {
      return {
        ...state,
        currentDetailFilterData: payload,
      };
    }
    case UPLOAD_POD_DOCUMENT: {
      return {
        ...state,
        podDocRequesting: true,
      };
    }
    case UPLOAD_POD_DOCUMENT_COMPLETED: {
      const updatedState = setUpdatedPodDetail(state, payload);
      return {
        ...updatedState,
        podDocRequesting: false,
      };
    }
    case UPLOAD_POD_DOCUMENT_FAILED: {
      return {
        ...state,
        podDocRequesting: false,
      };
    }
    case RESET_PAGE:
      return {
        ...state,
        resetPage: !state.resetPage,
      };
    case DOWNLOAD_REPORT:
      return {
        ...state,

        isDownloading: true,
      };

    case DOWNLOAD_REPORT_COMPLETED:
      return {
        ...state,

        isDownloading: false,
      };

    case DOWNLOAD_REPORT_FAILED:
      return {
        ...state,

        isDownloading: false,
      };
    default: {
      return state;
    }
  }
};
