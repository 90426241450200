import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE, TRIP_STATUS} from '../../../constants';
import * as _ from 'lodash';
import {Trips} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  isFetchingTripsTable: false,
  hasError: false,
  error: '',
  Isverified: false,
  billableData: '',
  unverifiedDryRunFilters: {},
};

const FETCH_UNVERIFIED_DRY_RUNS_COMPLETED =
  'FETCH_UNVERIFIED_DRY_RUNS_COMPLETED';
const FETCH_UNVERIFIED_DRY_RUNS = 'FETCH_UNVERIFIED_DRY_RUNS';
const FETCH_UNVERIFIED_DRY_RUNS_FAILED = 'FETCH_UNVERIFIED_DRY_RUNS_FAILED';

const POST_UNVERIFIED_DRY_RUNS_COMPLETED = 'POST_UNVERIFIED_DRY_RUNS_COMPLETED';
const POST_UNVERIFIED_DRY_RUNS = 'POST_UNVERIFIED_DRY_RUNS';
const POST_UNVERIFIED_DRY_RUNS_FAILED = 'POST_UNVERIFIED_DRY_RUNS_FAILED';

const FETCH_UNVERIFIED_DRY_RUN_TRIPS = 'FETCH_UNVERIFIED_DRY_RUN_TRIPS';
const FETCH_UNVERIFIED_DRY_RUN_TRIPS_COMPLETED =
  'FETCH_UNVERIFIED_DRY_RUN_TRIPS_COMPLETED';
const FETCH_UNVERIFIED_DRY_RUN_TRIPS_FAILED =
  'FETCH_UNVERIFIED_DRY_RUN_TRIPS_FAILED';

const SET_BILLABLE_DATA = 'SET_BILLABLE_DATA';
const SET_BILLABLE_DATA_FALSE = 'SET_BILLABLE_DATA_FALSE';

const RESET_UNVERIFIED_DRY_RUN_FILTERS = 'RESET_UNVERIFIED_DRY_RUN_FILTERS';

export const resetUnverifiedDryRunFilters = () => ({
  type: RESET_UNVERIFIED_DRY_RUN_FILTERS,
});

export const fetchUnverifiedDryRunsData = (search = '') => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_UNVERIFIED_DRY_RUNS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Trips.fetchUnverifiedDryRunURL(search),
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_UNVERIFIED_DRY_RUNS_COMPLETED,
          payload: {...data, search},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_UNVERIFIED_DRY_RUNS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_UNVERIFIED_DRY_RUNS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const verifyDryRun = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: POST_UNVERIFIED_DRY_RUNS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.fetchUnverifiedDryRunURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: POST_UNVERIFIED_DRY_RUNS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: POST_UNVERIFIED_DRY_RUNS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: POST_UNVERIFIED_DRY_RUNS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchUnverifiedDryRunTripReport = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_UNVERIFIED_DRY_RUN_TRIPS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Trips.fetchUnverifiedDryRunTripReportURL(),
        params: requestData,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_UNVERIFIED_DRY_RUN_TRIPS_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: FETCH_UNVERIFIED_DRY_RUN_TRIPS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_UNVERIFIED_DRY_RUN_TRIPS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const setBillableData = (data) => {
  return (dispatch) => {
    dispatch({type: SET_BILLABLE_DATA, payload: data});
  };
};
export const resetBillableData = () => {
  return (dispatch) => {
    dispatch({type: SET_BILLABLE_DATA, payload: []});
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_UNVERIFIED_DRY_RUNS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        Isverified: false,

        error: '',
      };
    case FETCH_UNVERIFIED_DRY_RUNS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        error: '',
        Isverified: false,
        unverifiedDryRunFilters: payload?.filters,
        data: payload,
      };

    case FETCH_UNVERIFIED_DRY_RUNS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        Isverified: false,

        error: payload.error,
      };

    case RESET_UNVERIFIED_DRY_RUN_FILTERS:
      return {
        ...state,
        unverifiedDryRunFilters: {},
      };

    case FETCH_UNVERIFIED_DRY_RUN_TRIPS:
      return {
        ...state,
        isFetchingTripsTable: true,
      };
    case FETCH_UNVERIFIED_DRY_RUN_TRIPS_COMPLETED:
      return {
        ...state,
        isFetchingTripsTable: false,
        customerTripReport: Object.assign({}, payload),
      };
    case FETCH_UNVERIFIED_DRY_RUN_TRIPS_FAILED:
      return {
        ...state,
        isFetchingTripsTable: false,
        customerTripReport: {},
      };
    case POST_UNVERIFIED_DRY_RUNS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        Isverified: false,
        error: '',
      };
    case POST_UNVERIFIED_DRY_RUNS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        Isverified: true,

        error: '',
      };

    case POST_UNVERIFIED_DRY_RUNS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: payload.error,
        Isverified: false,
      };
    case SET_BILLABLE_DATA:
      return {
        ...state,
        billableData: payload,
      };
    case SET_BILLABLE_DATA_FALSE:
      return {
        ...state,
        billableData: '',
      };

    default:
      return state;
  }
};
