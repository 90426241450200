import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Reports} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  error: '',
  vehicleReports: {},
  vehicleSummary: {},
  vehicleInactiveDays: [],
  vendorIds: [],
  isDownloading: false,
};

const FETCH_VEHICLE_AVERAGE_RUN = 'FETCH_VEHICLE_AVERAGE_RUN';
const FETCH_VEHICLE_AVERAGE_RUN_COMPLETED =
  'FETCH_VEHICLE_AVERAGE_RUN_COMPLETED';
const FETCH_VEHICLE_AVERAGE_RUN_FAILED = 'FETCH_VEHICLE_AVERAGE_RUN_FAILED';

const VEHICLE_SUMMARY = 'VEHICLE_SUMMARY';
const VEHICLE_SUMMARY_COMPLETED = 'VEHICLE_SUMMARY_COMPLETED';
const VEHICLE_SUMMARY_FAILED = 'VEHICLE_SUMMARY_FAILED';

const DOWNLOAD_AVG_RUN_REPORT = 'DOWNLOAD_AVG_RUN_REPORT';
const DOWNLOAD_AVG_RUN_REPORT_COMPLETED = 'DOWNLOAD_AVG_RUN_REPORT_COMPLETED';
const DOWNLOAD_AVG_RUN_REPORT_FAILED = 'DOWNLOAD_AVG_RUN_REPORT_FAILED';

export const downloadAvgRunReport = (month) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_AVG_RUN_REPORT});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Reports.downloadAvgRunReportURL(month),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: DOWNLOAD_AVG_RUN_REPORT_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: DOWNLOAD_AVG_RUN_REPORT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_AVG_RUN_REPORT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchVehicleAverageRunReport = (month) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_VEHICLE_AVERAGE_RUN});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Reports.fetchVehicleAverageRunReportURL(month),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: FETCH_VEHICLE_AVERAGE_RUN_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_VEHICLE_AVERAGE_RUN_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_VEHICLE_AVERAGE_RUN_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchVehicleSummary = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: VEHICLE_SUMMARY});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Reports.fetchVehicleSummaryURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: VEHICLE_SUMMARY_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: VEHICLE_SUMMARY_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: VEHICLE_SUMMARY_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_VEHICLE_AVERAGE_RUN:
      return {...state, isFetching: true};
    case FETCH_VEHICLE_AVERAGE_RUN_COMPLETED:
      return {
        ...state,
        isFetching: false,
        vehicleReports: payload.vehicle_avg_report,
        vehicleSummary: payload.vehicles_summary,
        vendorIds: payload.vendor_report_ids,
      };
    case FETCH_VEHICLE_AVERAGE_RUN_FAILED:
      return {...state, isFetching: false, vendorIds: []};

    case VEHICLE_SUMMARY:
      return {...state, isFetchingVehicle: true};
    case VEHICLE_SUMMARY_COMPLETED:
      return {
        ...state,
        isFetchingVehicle: false,
        vehicleInactiveDays: payload.unavailable_record,
      };
    case VEHICLE_SUMMARY_FAILED:
      return {...state, isFetchingVehicle: false, vehicleInactiveDays: []};
    case DOWNLOAD_AVG_RUN_REPORT:
      return {...state, isDownloading: true};
    case DOWNLOAD_AVG_RUN_REPORT_COMPLETED:
    case DOWNLOAD_AVG_RUN_REPORT_FAILED:
      return {...state, isDownloading: false};
    default:
      return state;
  }
};
