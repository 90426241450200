import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Trips} from '../../../helpers/Urls';
import {CLOSE_MODAL} from '../../modal';

const INITIAL_STATE = {
  show_consent: false,
  show_dhanuka: false,
  only_show_dhanuka: false,
  trip_status: '',
  show_questions: false,
  dhanuka_options: {},
  driver_consent_options: {},
  dhanukaAnswers: {},
  driverConsentAnswers: {},
  questionnaireAnswers: {},
  driver_id: '',
  dhanuka_option_ids: [],
  driver_consent_option_ids: [],
};

const FETCH_TRIP_CONDITIONS = 'FETCH_TRIP_CONDITIONS';
const FETCH_TRIP_CONDITIONS_COMPLETED = 'FETCH_TRIP_CONDITIONS_COMPLETED';
const FETCH_TRIP_CONDITIONS_FAILED = 'FETCH_TRIP_CONDITIONS_FAILED';

const SET_DHANUKA_ANSWERS = 'SET_DHANUKA_ANSWERS';
const SET_DRIVER_CONSENT_ANSWERS = 'SET_DRIVER_CONSENT_ANSWERS';
const SET_QUESTIONNAIRE_ANSWERS = 'SET_QUESTIONNAIRE_ANSWERS';

export const setDhanukaAnswers = (data) => {
  return async (dispatch) => {
    dispatch({type: SET_DHANUKA_ANSWERS, payload: data});
  };
};

export const setDriverConsentAnswers = (data) => {
  return async (dispatch) => {
    dispatch({type: SET_DRIVER_CONSENT_ANSWERS, payload: data});
  };
};

export const setQuestionnaireAnswers = (data) => {
  return async (dispatch) => {
    dispatch({type: SET_QUESTIONNAIRE_ANSWERS, payload: data});
  };
};

export const checkTripBeginConditions = (trip_id) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TRIP_CONDITIONS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Trips.checkTripBeginConditionsURL(trip_id),
        data: {trip_id},
      });
      console.log('response --FETCH_TRIP_CONDITIONS--', response);
      const {data, status} = response || {};
      if (status === true) {
        dispatch({type: FETCH_TRIP_CONDITIONS_COMPLETED, payload: {...data}});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_TRIP_CONDITIONS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TRIP_CONDITIONS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_TRIP_CONDITIONS:
      return {...state, isFetching: true};
    case FETCH_TRIP_CONDITIONS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        show_consent: payload.show_consent,
        show_dhanuka: payload.show_dhanuka,
        only_show_dhanuka: payload.only_show_dhanuka,
        trip_status: payload.trip_status,
        show_questions: payload.show_questions,
        dhanuka_options: payload.dhanuka_options,
        dhanuka_option_ids: payload.dhanuka_option_ids,
        driver_consent_option_ids: payload.driver_consent_option_ids,
        driver_consent_options: payload.driver_consent_options,
        driver_id: payload.driver_id,
      };
    case FETCH_TRIP_CONDITIONS_FAILED:
      return {...state, isFetching: false};
    case SET_DHANUKA_ANSWERS:
      return {...state, dhanukaAnswers: {...payload}};
    case SET_DRIVER_CONSENT_ANSWERS:
      return {...state, driverConsentAnswers: {...payload}};
    case SET_QUESTIONNAIRE_ANSWERS:
      return {...state, questionnaireAnswers: {...payload}};
    case CLOSE_MODAL:
      return INITIAL_STATE;
    default:
      return state;
  }
};
