import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE} from '../../../constants';
import {Finance} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  uploadFinanceList: [],
  isUploading: false,
  downloadLink: '',
};

const RESET_PAGE = 'RESET_PAGE';

export const UPLOAD_FINANCE = 'UPLOAD_FINANCE';
export const UPLOAD_FINANCE_COMPLETED = 'UPLOAD_FINANCE_COMPLETED';
export const UPLOAD_FINANCE_FAILED = 'UPLOAD_FINANCE_FAILED';
export const UPLOAD_FINANCE_FAILED_WITH_DATA =
  'UPLOAD_FINANCE_FAILED_WITH_DATA';

export const FETCH_UPLOAD_FINANCE = 'FETCH_UPLOAD_FINANCE';
export const FETCH_UPLOAD_FINANCE_COMPLETED = 'FETCH_UPLOAD_FINANCE_COMPLETED';
export const FETCH_UPLOAD_FINANCE_FAILED = 'FETCH_UPLOAD_FINANCE_FAILED';

export const uploadFinance = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: UPLOAD_FINANCE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Finance.uploadFinanceURL(),
        data: requestData,
      });
      const {data, status, file_name} = response || {};
      if (status) {
        dispatch({
          type: UPLOAD_FINANCE_COMPLETED,
          payload: data,
        });
      } else if (!status && data && !file_name) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: UPLOAD_FINANCE_FAILED_WITH_DATA,
          payload: {error: message, data: data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: UPLOAD_FINANCE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: UPLOAD_FINANCE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchUploadFinance = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_UPLOAD_FINANCE});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Finance.fetchUploadFinanceURL(),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: FETCH_UPLOAD_FINANCE_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_UPLOAD_FINANCE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_UPLOAD_FINANCE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const resetPage = () => {
  return async (dispatch) => {
    dispatch({type: RESET_PAGE});
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    // fetch upload finance api
    case FETCH_UPLOAD_FINANCE:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_UPLOAD_FINANCE_COMPLETED:
      return {
        ...state,
        uploadFinanceList: payload?.advance_records,
        isFetching: false,
        downloadLink: payload?.template_url,
      };
    case FETCH_UPLOAD_FINANCE_FAILED:
      return {
        ...state,
        isFetching: false,
      };
    // upload finance api
    case UPLOAD_FINANCE:
      return {
        ...state,
        isUploading: true,
      };
    case UPLOAD_FINANCE_COMPLETED:
      return {
        ...state,
        isUploading: false,
        uploadFinanceList: payload,
      };
    case UPLOAD_FINANCE_FAILED_WITH_DATA:
      return {
        ...state,
        isUploading: false,
        uploadFinanceList: payload.data,
      };
    case UPLOAD_FINANCE_FAILED:
      return {
        ...state,
        isUploading: false,
      };
    // others
    case RESET_PAGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
