import {doRequest} from '../../../helpers/network';
import {PERFORMANCE_TABLE, REQUEST_TYPE} from '../../../constants';
import {Performance} from '../../../helpers/Urls';

const INITIAL_STATE = {
  isFetching: false,
  selectedTable: PERFORMANCE_TABLE.OVERALL,
  isDownloading: false,
  routes: [],
  fetchingRoute: false,
};

const FETCH_TRIP_PERFORMANCE = 'FETCH_TRIP_PERFORMANCE';
const FETCH_TRIP_PERFORMANCE_COMPLETED = 'FETCH_TRIP_PERFORMANCE_COMPLETED';
const FETCH_TRIP_PERFORMANCE_FAILED = 'FETCH_TRIP_PERFORMANCE_FAILED';

const DOWNLOAD_TRIP_PERFORMANCE = 'DOWNLOAD_TRIP_PERFORMANCE';
const DOWNLOAD_TRIP_PERFORMANCE_COMPLETED =
  'DOWNLOAD_TRIP_PERFORMANCE_COMPLETED';
const DOWNLOAD_TRIP_PERFORMANCE_FAILED = 'DOWNLOAD_TRIP_PERFORMANCE_FAILED';

const FETCH_ROUTE_FOR_CUSTOMER = 'FETCH_ROUTE_FOR_CUSTOMER';
const FETCH_ROUTE_FOR_CUSTOMER_COMPLETED = 'FETCH_ROUTE_FOR_CUSTOMER_COMPLETED';
const FETCH_ROUTE_FOR_CUSTOMER_FAILED = 'FETCH_ROUTE_FOR_CUSTOMER_FAILED';

const SELECT_TABLE = 'SELECT_TABLE';

export const fetchTripPerformance = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_TRIP_PERFORMANCE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Performance.fetchTripPerformanceURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: FETCH_TRIP_PERFORMANCE_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_TRIP_PERFORMANCE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_TRIP_PERFORMANCE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const selectTable = (selectedTable) => {
  return async (dispatch) => {
    dispatch({type: SELECT_TABLE, payload: {selectedTable}});
  };
};

export const downloadTripPerformance = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DOWNLOAD_TRIP_PERFORMANCE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Performance.downloadTripPerformanceURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: DOWNLOAD_TRIP_PERFORMANCE_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: DOWNLOAD_TRIP_PERFORMANCE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DOWNLOAD_TRIP_PERFORMANCE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchRoutes = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_ROUTE_FOR_CUSTOMER});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Performance.fetchRoutesURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: FETCH_ROUTE_FOR_CUSTOMER_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_ROUTE_FOR_CUSTOMER_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_ROUTE_FOR_CUSTOMER_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case FETCH_TRIP_PERFORMANCE:
      return {...state, isFetching: true};
    case FETCH_TRIP_PERFORMANCE_COMPLETED:
      return {
        ...state,
        isFetching: false,
        adhoc: payload.adhoc_performance,
        overall: payload.overall_performance,
        scheduled: payload.scheduled_performance,
      };
    case FETCH_TRIP_PERFORMANCE_FAILED:
      return {...state, isFetching: false};

    case DOWNLOAD_TRIP_PERFORMANCE:
      return {...state, isDownloading: true};
    case DOWNLOAD_TRIP_PERFORMANCE_COMPLETED:
    case DOWNLOAD_TRIP_PERFORMANCE_FAILED:
      return {...state, isDownloading: true};

    case FETCH_ROUTE_FOR_CUSTOMER:
      return {...state, fetchingRoute: true};
    case FETCH_ROUTE_FOR_CUSTOMER_COMPLETED:
      return {...state, fetchingRoute: false, routes: payload.routes};
    case FETCH_ROUTE_FOR_CUSTOMER_FAILED:
      return {...state, fetchingRoute: false};
    case SELECT_TABLE:
      return {...state, selectedTable: payload.selectedTable};

    default:
      return state;
  }
};
